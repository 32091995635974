import React from "react"

import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import "../styles/global.css"

import MobileNavbar from "../components/MobileNavbar"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ContactFooter from "../components/ContactFooter"

import Seo from "../components/seo"

const Article = ({ data }) => {
  const headerImageData = getImage(data.headerImage.gatsbyImageData)

  return (
    <div className="w-full flex flex-col justify-center items-center my-10">
      <div className="lg:w-8/12 w-10/12 flex flex-col justify-center items-center bg-card-footer p-4">
        <div>
          <GatsbyImage image={headerImageData} />
        </div>
        <div className="w-full justify-center items-center my-4 mt-5">
          <h1 className="2xl:text-5xl xl:text-4xl md:text-2xl text-2xl text-primary-dark uppercase text-center font-bold">
            {data.title}
          </h1>
        </div>
        <div className="w-3/4 justify-center items-center">
          <p className="2xl:text-xl md:text-sm text-sm  text-center text-text-primary font-medium">
            {data.subtitle}
          </p>
        </div>
        <div className="w-3/4 justify-center items-center my-3">
          <p className="2xl:text-sm md:text-xs text-xs  text-center text-primary-main font-medium">
            {data.createdAt}
          </p>
        </div>
        <div className="w-3/4 justify-center items-center">
          <p className="2xl:text-lg md:text-sm text-xs  text-center text-text-primary font-medium">
            by{" "}
            <span className="font-bold">
              {String(data.author)[0].toUpperCase() +
                String(data.author).slice(1)}
            </span>
          </p>
        </div>
        <div className="my-10 w-full justify-start items-start">
          <div className="text-lg text-gray-800 font-[courier] font-bold">
            {renderRichText(data.content, {})}
          </div>
        </div>
      </div>
    </div>
  )
}

export default function ArticlePost({ data }) {
  const pageData = data.allContentfulArticle.edges[0].node
  const imageSrc = pageData.headerImage.gatsbyImageData.images.fallback.src
  return (
    <>
      <Seo
        title={pageData.title}
        customMeta={{ description: `${pageData.subtitle}`, image: imageSrc }}
      />
      <div className="w-full flex flex-col justify-center items-center font-helvetica my-5">
        <div className="md:hidden w-full flex flex-col justify-center items-center font-helvetica">
          <MobileNavbar>
            <Article data={pageData} />
            <Footer />
            <ContactFooter />
          </MobileNavbar>
        </div>
        <div className="md:visible md:w-full md:flex md:flex-col md:justify-center md:items-center md:font-helvetica hidden">
          <Navbar />
          <Article data={pageData} />
          <Footer />
          <ContactFooter />
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ArticleComponentQuery($contentful_id: String!) {
    allContentfulArticle(filter: { contentful_id: { eq: $contentful_id } }) {
      edges {
        node {
          author
          createdAt(formatString: "DD MMMM YYYY")
          headerImage {
            gatsbyImageData(formats: PNG)
          }
          subtitle
          title
          content {
            raw
          }
        }
      }
    }
  }
`
